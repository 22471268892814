/** @jsx jsx */
import React from "react"
import styled from "@emotion/styled"
import { jsx } from "@emotion/react"
import { GridBase, ContentLimit, GameContentLimit } from "../../helpers/grid"

interface PostListProps {
  isGameList?: boolean
  children: any
}

const BlogSection = styled.section`
  min-height: 100%;
  ${GridBase};
  @media (max-width: 768px) {
    padding-top: 2vh;
  }
`

const BlogWrap = styled.div()

const PostsListsWrap = ({ isGameList, children }: PostListProps) => (
  <BlogSection>
    <BlogWrap css={isGameList ? GameContentLimit : ContentLimit}>
      {children}
    </BlogWrap>
  </BlogSection>
)

export default PostsListsWrap
