/** @jsx jsx */
import React from "react"
import Helmet from "react-helmet"
import styled from "@emotion/styled"
import { css, jsx } from "@emotion/react"

import config from "../../config/site-config"
import Default from "../components/Layouts/Default"
import SEO from "../components/SEO/SEO"
import { ContentLimit, GridBase } from "../helpers/grid"
import { colorScheme } from "../helpers/styleSettings"

const Privacy = (): JSX.Element => {
  return (
    <Default>
      <CareerWrap>
        <SEO />
        <Helmet>
          <title>{`Terms of Service | ${config.siteName}`}</title>
          <link rel="canonical" href={`${config.siteUrl}/terms-of-service/`} />
        </Helmet>
        <CareerContent>
          <h1>Terms of Service</h1>
          <p>
            Ohoy! This is A Bit Ago’s terms of service. More to come in the
            future...
          </p>

          <h3>Contact Us</h3>
          <p>
            If you have any questions or concerns about our Terms of Service,
            please contact us at{" "}
            <a css={navItem} href="mailto:studio@abitago.se">
              studio@abitago.se
            </a>
            .
          </p>
        </CareerContent>
      </CareerWrap>
    </Default>
  )
}

const CareerWrap = styled.div`
  ${GridBase};
  padding-top: 60px;
`

const navItem = css`
  text-decoration: none;
  color: ${colorScheme.text};
  transition: color 0.3s ease-out;

  &:hover {
    color: ${colorScheme.support};
    background-size: 100% 100%;
  }
`

const CareerContent = styled.div`
  z-index: 501;
  position: relative;
  height: 100%;
  min-height: calc(100vh - 60px);
  /* padding-bottom: 4em; */
  padding: 0 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${colorScheme.text};
  ${ContentLimit};

  > div {
    max-width: 70ch;
    @media (min-width: 1024px) {
      max-width: 220ch;
    }
  }

  h1 {
    color: ${colorScheme.support};
    font-size: 2em;
    font-weight: 900;
    margin: 0;
    @media (min-width: 768px) {
      font-size: 3em;
    }
    @media (min-width: 1024px) {
      font-size: 5em;
    }
  }

  h2 {
    font-size: 1.75em;
    margin: 0;
    @media (min-width: 768px) {
      font-size: 2.25em;
    }
    @media (min-width: 1024px) {
      font-size: 4em;
    }
  }

  p {
    line-height: 1.55;
    font-size: 1.125em;
    @media (min-width: 768px) {
      font-size: 1.25em;
    }
    @media (min-width: 768px) {
      font-size: 1.45em;
      line-height: 1.25;
    }
  }

  a {
    text-decoration: none;
    color: ${colorScheme.supportPink};
    &:hover {
      text-decoration: underline;
    }
  }
`
export default Privacy
