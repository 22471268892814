import React from "react"
import styled from "@emotion/styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faMastodon,
} from "@fortawesome/free-brands-svg-icons"

import { GridBase } from "../../helpers/grid"
import { colorScheme } from "../../helpers/styleSettings"
import { Link } from "gatsby-link"

const SiteFooter = (): JSX.Element => (
  <FooterWrap>
    <SocialContainer>
      <ul>
        <li>
          <a
            rel="me"
            href="https://mastodon.gamedev.place/@abitago"
            target="_blank noreferrer"
            className="social mastodon"
          >
            <FontAwesomeIcon icon={faMastodon} size="2x" />
          </a>
        </li>
        <li>
          <a
            href="http://instagram.com/abitagostudio"
            target="_blank noreferrer"
            title="A bit ago on Instagram"
            rel="noopener"
            className="social instagram"
          >
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>
        </li>
        <li>
          <a
            href="http://twitter.com/abitagostudio"
            target="_blank noreferrer"
            rel="noopener"
            className="social twitter"
          >
            <FontAwesomeIcon icon={faTwitter} size="2x" />
          </a>
        </li>
        <li>
          <a
            href="http://facebook.com/abitagostudio"
            target="_blank noreferrer"
            rel="noopener"
            className="social facebook"
          >
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </a>
        </li>
      </ul>
    </SocialContainer>
    <CopyContainer>
      <p>
        Read our <Link to="/privacy">Privacy policy</Link> and{" "}
        <Link to="/terms-of-service">Terms of service</Link>. A Bit Ago &copy;{" "}
        {new Date().toLocaleDateString("sv-SE", { year: "numeric" })}.
      </p>
    </CopyContainer>
  </FooterWrap>
)

const FooterWrap = styled.div`
  ${GridBase};
  margin-top: 4vw;
  @media (max-width: 768px) {
    margin-top: 2ch;
  }
`

const CopyContainer = styled.div`
  z-index: 501;
  position: relative;
  display: flex;
  padding: 0 1em 60px 1em;
  flex-direction: column;
  justify-content: center;
  grid-column: 4 / 10;
  text-align: center;
  > div {
    max-width: 70ch;
    @media (min-width: 1024px) {
      max-width: 220ch;
    }
  }
`

const SocialContainer = styled.div`
  z-index: 501;
  position: relative;
  display: flex;
  padding: 0 1em 60px 1em;
  flex-direction: column;
  justify-content: center;
  grid-column: 4 / 10;
  text-align: center;
  ul {
    margin: 0;
    padding: 0;
  }
  > div {
    max-width: 70ch;
    @media (min-width: 1024px) {
      max-width: 220ch;
    }
  }

  a.social {
    margin: 0 1rem;
    transition: transform 250ms;
  }

  a.social:hover {
    transform: translateY(-2px);
    color: ${colorScheme.darkLight};
  }

  a.mastodon {
    color: ${colorScheme.supportBlue};
  }
  a.instagram {
    color: ${colorScheme.supportPink};
  }
  a.twitter {
    color: ${colorScheme.supportYellow};
  }
  a.facebook {
    color: ${colorScheme.supportGreen};
  }

  & ul {
    margin: 0;
    list-style: none;
    @media (max-width: 768px) {
      justify-content: center;
      padding: 0;
    }
    & li {
      display: inline-block;
    }
  }
`

export default SiteFooter
