import React from "react"
import { Helmet } from "react-helmet"
import { Global } from "@emotion/react"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faCheckSquare, faCoffee } from "@fortawesome/free-solid-svg-icons"

library.add(fab, faCheckSquare, faCoffee)

import "normalize.css"

const FallbackComponent = () => <div>Uh oh! There was an error :(</div>

import config from "../../../config/site-config"
import SiteHeader from "../SiteHeader/SiteHeader"
import SiteFooter from "../SiteFooter/SiteFooter"
import { globalCss } from "../../helpers/global"

const TemplateWrapper = ({ children }: any): JSX.Element => (
  <>
    <Global styles={globalCss} />
    <Helmet title={config.siteDescription} htmlAttributes={{ lang: "EN" }} />
    <SiteHeader />
    {children}
    <SiteFooter />
  </>
)

export default TemplateWrapper
