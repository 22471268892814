import "../graphql/archive"

import { graphql } from "gatsby"
import React from "react"
import Helmet from "react-helmet"

import config from "../../config/site-config"
import Default from "../components/Layouts/Default"
import PostsList from "../components/PostsList/PostsList"
import PostsListWrap from "../components/PostsListWrap/PostsListWrap"
import SEO from "../components/SEO/SEO"
import { AllMarkdownRemark } from "../types"

interface BlogListProps {
  data: {
    allMarkdownRemark: AllMarkdownRemark
  }
}
const BlogList = (props: BlogListProps) => {
  const { edges } = props.data.allMarkdownRemark
  return (
    <Default>
      <SEO />
      <Helmet>
        <title>{`Blog | ${config.siteName}`}</title>
        <link rel="canonical" href={`${config.siteUrl}/blog`} />
      </Helmet>
      <PostsListWrap>
        <PostsList edges={edges} />
      </PostsListWrap>
    </Default>
  )
}

export default BlogList

export const query = graphql`
  query PostsArchive {
    allMarkdownRemark(
      filter: { frontmatter: { category: { ne: "games" } } }
      sort: { frontmatter: { date: DESC } }
    ) {
      totalCount
      edges {
        node {
          ...defaultArchiveQuery
        }
      }
    }
  }
`
