import React, { useState } from "react"
import styled from "@emotion/styled"
import { colorScheme } from "../../helpers/styleSettings"
import { Link } from "gatsby-link"
import { useForm } from "react-hook-form"

type FormData = {
  email: string
}

const duration = 300

const ErrorMessage = styled.div`
  color: red;
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear ${duration}ms, opacity ${duration}ms ease-in;
  padding-top: 1rem;
  padding-bottom: 1rem;

  &.show {
    opacity: 1;
    visibility: visible;
    transition-delay: ${duration}ms;
  }
`

const SuccessMessage = styled.div`
  color: green;
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear ${duration}ms, opacity ${duration}ms ease-in;
  margin: 50px auto;
  max-width: 36rem;
  text-wrap: balance;

  &.show {
    opacity: 1;
    visibility: visible;
    transition-delay: ${duration}ms;
  }
`

const FormWrapper = styled.section`
  @media (max-width: 768px) {
    text-align: center;
  }
  margin: 4em auto;
  --mark-color: #eee;
  --mark-skew: 3em;
  --mark-height: 22em;
  --mark-overlap: 3em;
  @media (max-width: 768px) {
    --mark-skew: 3em;
    --mark-height: 22em;
    --mark-overlap: 3em;
  }
  margin-inline: calc(var(--mark-overlap) * -1);
  padding-inline: var(--mark-overlap);

  background-color: transparent;
  background-image: linear-gradient(
      to bottom right,
      transparent 50%,
      var(--mark-color) 50%
    ),
    linear-gradient(var(--mark-color), var(--mark-color)),
    linear-gradient(to top left, transparent 50%, var(--mark-color) 50%);
  background-size: var(--mark-skew) var(--mark-height),
    calc(100% - var(--mark-skew) * 2 + 1px) var(--mark-height),
    var(--mark-skew) var(--mark-height);
  background-position: left center, center, right center;
  background-repeat: no-repeat;
  color: inherit;
  min-height: calc(10vh - 20px);
`

const FormTextWrapper = styled.p`
  clear: both;
  display: block;
  font-size: 1em !important;
  font-weight: 700;
  color: ${colorScheme.supportDark};
`

const InputWrapper = styled.div`
  float: left;
  width: 80%;
  @media (max-width: 768px) {
    width: 100%;
    float: none;
  }
`

const InputField = styled.input`
  border: 1px solid #ddd;
  width: 100%;
  height: 2.7em;
  padding: 0.7em;
  border-radius: 4px;
`

const InputButton = styled.button`
  float: left;
  display: inline-block;
  background: #000;
  border-radius: 4px;
  border: 0;
  line-height: 1;
  text-transform: uppercase;
  color: #fff;
  padding: 0.7em;
  margin-left: 1.27em;
  height: 2.7em;
  font-family: "proxima-nova", sans-serif;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  @media (max-width: 768px) {
    margin: 0 auto;
    float: none;
  }
`

const InputFieldWrapper = styled.div`
  vertical-align: middle;
`

const EmailForm = () => {
  const {
    register,
    setError,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>()
  const handleError = (errors: any) => console.error("Error", errors)
  const [successMessage, setSuccessMessage] = useState("")

  const onSubmit = async (data: FormData) => {
    try {
      console.log("Post something", JSON.stringify(data))
      const response = await fetch("/api/subscribe-mailjet", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      // Success reset form and add success message
      reset()
      setSuccessMessage(
        "Awesomness! Thank you for subscribing to our newsletter! We respect your privacy and promise not to spam!"
      )
    } catch (error: any) {
      setError("email", {
        type: "custom",
        message:
          "We're sorry, but we couldn't add your email address to our newsletter list. Please double-check that you entered the correct email address and try again. If you continue to have issues, please contact us!",
      })
    }
  }

  return (
    <FormWrapper>
      <SuccessMessage role="alert" className={successMessage ? "show" : ""}>
        {successMessage}
      </SuccessMessage>
      {!successMessage && (
        <form
          onSubmit={handleSubmit((data) => onSubmit(data), handleError)}
          name="newsletter-form"
          method="POST"
          action="/"
        >
          <FormTextWrapper>
            Stay up to date with our current and upcoming game development
            projects by joining our Newsletter:
          </FormTextWrapper>
          <InputFieldWrapper>
            <InputWrapper>
              <InputField
                type="email"
                {...register("email", {
                  required:
                    "Oops! Looks like you forgot to enter your email address. Please enter your email address so we can keep you updated with our latest news and updates.",
                })}
                placeholder="Your e-mail here"
                aria-invalid={errors.email ? "true" : "false"}
              />

              <ErrorMessage
                role="alert"
                className={errors?.email ? "show" : ""}
              >
                {errors?.email?.message}
              </ErrorMessage>
            </InputWrapper>
            <InputButton type="submit">Sign up</InputButton>
          </InputFieldWrapper>
          <FormTextWrapper>
            <Link to="/privacy">We respect your privacy</Link>.
          </FormTextWrapper>
        </form>
      )}
    </FormWrapper>
  )
}

export default EmailForm
