import replace from "lodash/replace"

export const preventWidow = (value: string) =>
  replace(value, /\s(?=[^\s]*$)/g, "\u00a0")

/**
 * Funtion to prevent default behaviour when click an A tag.
 * Takes in an event (e) and a string (slug)
 */
import { navigate } from "gatsby"
export const goToPage = (e: any, slug: string) => {
  if (e.target.tagName === "A") {
    e.preventDefault()
  } else {
    navigate(slug)
  }
}
