import mc from "material-colors"
export const colorScheme = {
  dark: "#fff",
  darkLight: mc.grey[100],
  support: mc.black,
  supportDark: mc.grey[700],
  supportLight: mc.black,
  supportLightGrey: "#ddd",
  text: mc.black,
  logo: mc.black,
  meta: mc.grey[800],
  supportPink: "#F768A1",
  supportGreen: "#7ED321",
  supportYellow: "#F8E71C",
  supportLightBlue: "#50E3C2",
  supportBlue: "#50EE",
}
// #F768A1
// #00A757

export const fontScheme = {
  text: "'Varela Round', sans-serif",
  headings: "'Fredoka One', sans-serif",
}
