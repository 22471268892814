import { css } from "@emotion/react"
import { colorScheme, fontScheme } from "./styleSettings"

export const globalCss = css`
  * {
    box-sizing: border-box;
  }

  body {
    font-family: ${fontScheme.text};
    background: ${colorScheme.dark};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${fontScheme.headings};
    font-weight: 900;
    letter-spacing: 2px;
    color: ${colorScheme.text};
  }

  // Add more font-size here
  h3 {
    font-size: 2em;
    margin: 0;
    @media (min-width: 768px) {
      font-size: 1.57em;
    }
    @media (min-width: 1024px) {
      font-size: 3em;
    }
  }

  a {
    color: ${colorScheme.support};
  }

  p {
    line-height: 1.55;
  }

  mark {
    --mark-color: ${colorScheme.supportYellow};
    --mark-skew: 0.25em;
    --mark-height: 1em;
    --mark-overlap: 0.3em;

    margin-inline: calc(var(--mark-overlap) * -1);
    padding-inline: var(--mark-overlap);

    background-color: transparent;
    background-image: linear-gradient(
        to bottom right,
        transparent 50%,
        var(--mark-color) 50%
      ),
      linear-gradient(var(--mark-color), var(--mark-color)),
      linear-gradient(to top left, transparent 50%, var(--mark-color) 50%);
    background-size: var(--mark-skew) var(--mark-height),
      calc(100% - var(--mark-skew) * 2 + 1px) var(--mark-height),
      var(--mark-skew) var(--mark-height);
    background-position: left center, center, right center;
    background-repeat: no-repeat;
    color: inherit;
  }
`
