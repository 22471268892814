module.exports = {
  siteName: "abitago.se",
  siteLogo: "/logo/logo_space.png",
  siteUrl: "https://www.abitago.se",
  siteTitle: "A bit ago",
  // Website description used for RSS feeds/meta description tag.
  siteDescription:
    "A Bit Ago is a indie game studio based in Sundsvall, Sweden.",
  siteLanguage: "en_US",
  siteLinks: {
    github: {
      handle: "a-bit-ago",
    },
    twitter: {
      handle: "abitagostudio",
    },
    discord: {
      handle: "abitagostudio",
    },
    instagram: {
      handle: "abitagostudio",
    },
    email: {
      email: "studio@abitago.se",
    },
  },
}
