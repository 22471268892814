import "../graphql/game"

import styled from "@emotion/styled"
import { graphql } from "gatsby"
import React from "react"
import Helmet from "react-helmet"

import config from "../../config/site-config"
import Default from "../components/Layouts/Default"
import SEO from "../components/SEO/SEO"
import { ContentLimit, GridBase } from "../helpers/grid"
import { colorScheme } from "../helpers/styleSettings"
import { AllMarkdownRemark } from "../types"
import PostsListWrap from "../components/PostsListWrap/PostsListWrap"
import PostsList from "../components/PostsList/PostsList"

interface GamesListProps {
  data: {
    allMarkdownRemark: AllMarkdownRemark
  }
}

const Games = (props: GamesListProps) => {
  const { edges } = props.data.allMarkdownRemark
  return (
    <Default>
      <GamesWrap>
        <SEO />
        <Helmet>
          <title>{`Games | ${config.siteName}`}</title>
          <link rel="canonical" href={`${config.siteUrl}/games`} />
        </Helmet>
        <GamesContent>
          <h1>Games</h1>
          <p>
            We want our games to leave a lasting impression so we lean into real
            life experiences and interests outside of games to create
            experiences that feel personal and relatable.
          </p>
          <PostsListWrap isGameList>
            <PostsList edges={edges} />
          </PostsListWrap>
        </GamesContent>
      </GamesWrap>
    </Default>
  )
}

const GamesWrap = styled.div`
  ${GridBase};
  padding-top: 40vh;
  @media (max-width: 768px) {
    padding-top: 2vh;
  }
`

const GamesContent = styled.div`
  z-index: 501;
  position: relative;
  height: 100%;
  min-height: calc(100vh - 60px);
  padding: 0 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${colorScheme.text};
  ${ContentLimit};

  > div {
    max-width: 70ch;
    @media (min-width: 1024px) {
      max-width: 220ch;
    }
  }

  h1 {
    color: ${colorScheme.support};
    font-size: 2em;
    font-weight: 900;
    margin: 0;
    @media (min-width: 768px) {
      font-size: 3em;
    }
    @media (min-width: 1024px) {
      font-size: 5em;
    }
  }

  h2 {
    font-size: 1.5em;
    margin: 0;
    @media (min-width: 768px) {
      font-size: 2.25em;
    }
    @media (min-width: 1024px) {
      font-size: 3em;
    }
  }

  p {
    line-height: 1.55;
    font-size: 1.125em;
    /* @media (min-width: 768px) {
      font-size: 1.25em;
    } */
    @media (min-width: 768px) {
      font-size: 1.45em;
      line-height: 1.25;
    }
  }

  a {
    text-decoration: none;
    color: ${colorScheme.supportPink};
    &:hover {
      text-decoration: underline;
    }
  }
`
export default Games

export const query = graphql`
  query GamesArchive {
    allMarkdownRemark(
      filter: { frontmatter: { category: { eq: "games" } } }
      sort: { frontmatter: { date: DESC } }
    ) {
      totalCount
      edges {
        node {
          ...defaultGameQuery
        }
      }
    }
  }
`
