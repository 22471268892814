import styled from "@emotion/styled"
import React from "react"
import Helmet from "react-helmet"

import config from "../../config/site-config"
import Default from "../components/Layouts/Default"
import SEO from "../components/SEO/SEO"
import { ContentLimit, GridBase } from "../helpers/grid"
import { colorScheme } from "../helpers/styleSettings"

const AboutUs = (): JSX.Element => {
  return (
    <Default>
      <AboutUsWrap>
        <SEO />
        <Helmet>
          <title>{`Our story | ${config.siteName}`}</title>
          <link rel="canonical" href={`${config.siteUrl}/our-story/`} />
        </Helmet>
        <AboutUsContent>
          <h3>
            Founded by Gustaf Lindqvist in 2020 for the love to a great story
            and deeper meaning that games can give you.
          </h3>
          <p>
            We’re a small (very passionate) indie studio based in Sundsvall,
            Sweden. We believe in having our people, clients and partners in the
            center. That gives us collective super powers. We strive hard to
            have optimal conditions for <mark>collaboration</mark>, in an
            environment of <mark>diverse</mark> backgrounds. Curiosity, open
            minds, and equality empowers us.
          </p>
        </AboutUsContent>
      </AboutUsWrap>
    </Default>
  )
}

const AboutUsWrap = styled.div`
  ${GridBase};
  padding-top: 60px;
`

const AboutUsContent = styled.div`
  z-index: 501;
  position: relative;
  height: 100%;
  min-height: calc(100vh - 60px);
  /* padding-bottom: 4em; */
  padding: 0 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${colorScheme.text};
  ${ContentLimit};

  > div {
    max-width: 70ch;
    @media (min-width: 1024px) {
      max-width: 220ch;
    }
  }

  h1 {
    color: ${colorScheme.support};
    font-size: 2em;
    font-weight: 900;
    margin: 0;
    @media (min-width: 768px) {
      font-size: 3em;
    }
    @media (min-width: 1024px) {
      font-size: 5em;
    }
  }

  h2 {
    font-size: 1.75em;
    margin: 0;
    @media (min-width: 768px) {
      font-size: 2.25em;
    }
    @media (min-width: 1024px) {
      font-size: 4em;
    }
  }

  p {
    line-height: 1.55;
    font-size: 1.125em;
    @media (min-width: 768px) {
      font-size: 1.25em;
    }
    @media (min-width: 768px) {
      font-size: 1.45em;
      line-height: 1.25;
    }
  }

  a {
    color: ${colorScheme.supportPink};
    text-decoration: 3px solid underline;
    webkit-text-decoration: 3px solid solid underline;
    -webkit-text-underline-position: auto;
    text-underline-position: auto;
    &:hover {
      text-decoration: none;
    }
  }
`
export default AboutUs
