/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import styled from "@emotion/styled"
import React from "react"
import Helmet from "react-helmet"

import config from "../../config/site-config"
import Default from "../components/Layouts/Default"
import SEO from "../components/SEO/SEO"
import { ContentLimit, GridBase } from "../helpers/grid"
import { colorScheme } from "../helpers/styleSettings"

const Privacy = (): JSX.Element => {
  return (
    <Default>
      <CareerWrap>
        <SEO />
        <Helmet>
          <title>{`Privacy policy | ${config.siteName}`}</title>
          <link rel="canonical" href={`${config.siteUrl}/privacy/`} />
        </Helmet>
        <CareerContent>
          <h1>Privacy policy</h1>
          <p>Our Privacy Policy was last updated on April 16th, 2023</p>
          <p>
            At A Bit Ago, we respect your privacy and are committed to
            protecting any personal information you share with us. This Privacy
            Policy explains how we collect, use, and disclose your information
            when you use our website or our apps.
          </p>

          <h2>Website</h2>
          <p>
            Our website is intended for parents and guardians, not children
            under 13. We do not knowingly collect personal information from
            children under 13. If you are a parent or guardian and believe we
            have collected personal information about your child, please contact
            us so we can delete it.
          </p>
          <p>
            When you visit our website, we may collect non-personally
            identifying information such as your browser type, language
            preference, and the date and time of your visit. We use this
            information to better understand how visitors use our website and to
            improve our services.
          </p>

          <h2>Apps</h2>
          <p>
            Our apps are designed for the whole family, including young children
            with parent supervision. We do not collect personal information from
            children under 13 without parental consent. When you use our apps,
            we may collect non-personally identifying information such as the
            device type, operating system, and app usage data. We use this
            information to improve our apps and to better understand how users
            interact with them.
          </p>
          <p>
            We may also collect personal information such as your email address
            if you choose to contact us for support or to provide feedback. We
            will use this information only for the purpose of responding to your
            inquiries or providing support.
          </p>

          <h2>Disclosure of Information</h2>
          <p>
            We do not share, sell, or rent your personal information to third
            parties for marketing purposes. We may share your information with
            our trusted service providers who assist us in providing our
            services, such as hosting and analytics. We require these service
            providers to maintain the confidentiality of your information and to
            use it only for the purpose of providing services to us.
          </p>

          <h3>Personal Information</h3>
          <p>
            We may collect personal information from you, such as your name,
            email address, and phone number, when you contact us or sign up for
            our newsletter. We use this information to communicate with you and
            to improve our services.
          </p>
          <p>
            We also collect device information, such as your IP address and
            browser type, when you use our website or services. We use this
            information to analyze how our website and services are being used
            and to improve our services.
          </p>

          <h3>Children's Privacy</h3>
          <p>
            We take children's privacy very seriously. We will never knowingly
            collect personal information from children under the age of three
            without parental consent. For children aged three and above, we will
            only collect personal information with parental consent.
          </p>
          <p>
            We may collect in-game actions, device information, and other
            non-personal information from children to improve our services and
            personalize the user experience. We do not sell this information to
            third parties.
          </p>
          <p>
            We allow parents to review and delete their child's personal
            information at any time by contacting us at{" "}
            <a css={navItem} href="mailto:studio@abitago.se">
              studio@abitago.se
            </a>
            .
          </p>

          <h3>Sharing Information</h3>
          <p>
            We do not share your personal information or your child's personal
            information with third parties, except as required by law or to
            provide our services.
          </p>

          <h3>Security</h3>
          <p>
            We take reasonable measures to protect your personal information and
            your child's personal information from unauthorized access, use, or
            disclosure. We use secure servers and data encryption to protect
            your information.
          </p>

          <h2>Changes to this Privacy Policy</h2>
          <p>
            We may update this Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on our website
            and, if applicable, within our apps. You are advised to review this
            Privacy Policy periodically for any changes.
          </p>

          <h3>Contact Us</h3>
          <p>
            If you have any questions or concerns about our privacy policy,
            please contact us at{" "}
            <a css={navItem} href="mailto:studio@abitago.se">
              studio@abitago.se
            </a>
            .
          </p>
        </CareerContent>
      </CareerWrap>
    </Default>
  )
}

const CareerWrap = styled.div`
  ${GridBase};
  padding-top: 60px;
`

const navItem = css`
  text-decoration: none;
  color: ${colorScheme.text};
  transition: color 0.3s ease-out;

  &:hover {
    color: ${colorScheme.support};
    background-size: 100% 100%;
  }
`

const CareerContent = styled.div`
  z-index: 501;
  position: relative;
  height: 100%;
  min-height: calc(100vh - 60px);
  /* padding-bottom: 4em; */
  padding: 0 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${colorScheme.text};
  ${ContentLimit};

  > div {
    max-width: 70ch;
    @media (min-width: 1024px) {
      max-width: 220ch;
    }
  }

  h1 {
    color: ${colorScheme.support};
    font-size: 2em;
    font-weight: 900;
    margin: 0;
    @media (min-width: 768px) {
      font-size: 3em;
    }
    @media (min-width: 1024px) {
      font-size: 5em;
    }
  }

  h2 {
    font-size: 1.75em;
    margin: 0;
    @media (min-width: 768px) {
      font-size: 2.25em;
    }
    @media (min-width: 1024px) {
      font-size: 4em;
    }
  }

  p {
    line-height: 1.55;
    font-size: 1.125em;
    @media (min-width: 768px) {
      font-size: 1.25em;
    }
    @media (min-width: 768px) {
      font-size: 1.45em;
      line-height: 1.25;
    }
  }

  a {
    text-decoration: none;
    color: ${colorScheme.supportPink};
    &:hover {
      text-decoration: underline;
    }
  }
`
export default Privacy
