import * as React from "react"
import styled from "@emotion/styled"
import { graphql } from "gatsby"
import includes from "lodash/includes"
import find from "lodash/find"

import Default from "../components/Layouts/Default"
import SEO from "../components/SEO/SEO"

import { ContentLimit, GridBase } from "../helpers/grid"
import { colorScheme } from "../helpers/styleSettings"
import { AllFile, AllMarkdownRemark } from "../types"
import EmailForm from "../components/EmailForm/EmailForm"

export interface HomeProps {
  data: {
    allFile: AllFile
    allMarkdownRemark: AllMarkdownRemark
  }
}

const EntryTitle = styled.div`
  .charGlowing {
    background: -webkit-linear-gradient(
      0deg,
      ${colorScheme.supportPink},
      #1cffff,
      ${colorScheme.supportGreen},
      ${colorScheme.supportYellow}
    );
    background-size: 100% 100%;
    background-attachment: fixed;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textglowing 25s ease infinite;
  }
  @keyframes textglowing {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`

const EntryContactInfo = styled.div`
  text-align: left;
`

const Home = (props: HomeProps): JSX.Element => {
  const { allFile } = props.data
  const bg = find(allFile.edges, (edge) =>
    edge.node.name ? includes(edge.node.name, "bg") : false
  )
  return (
    <Default>
      <HomeWrap>
        {bg && bg.node.childImageSharp && bg.node.childImageSharp.fluid && (
          <HomeBackground src={bg.node.childImageSharp.fluid.tracedSVG} />
        )}
        <HomeContent>
          <EntryTitle>
            {/* <VideoLoop /> */}
            <h1>
              Hi, welcome to join us in{" "}
              <span className="charGlowing">exploring</span> the future
            </h1>
            {/* <h2>
              Bringing a physical space to life with crafted visions and with a
              great story
            </h2> */}
            <div>
              <p>
                We are a small indie studio{" "}
                <mark>based in Sundsvall, Sweden</mark>. You can follow us on{" "}
                <a
                  href="http://twitter.com/abitagostudio"
                  target="_blank noreferrer"
                  rel="noopener"
                >
                  Twitter
                </a>{" "}
                for random shower thoughts.
              </p>{" "}
            </div>
            <EntryContactInfo>
              <p>
                Feel free to{" "}
                <a
                  href="mailto:studio@abitago.se"
                  target="_blank noreferrer"
                  title="Send an e-mail to us"
                  rel="noopener"
                >
                  contact us
                </a>
                .{" "}
              </p>
            </EntryContactInfo>
          </EntryTitle>
          <EmailForm />
        </HomeContent>
        <SEO />
      </HomeWrap>
    </Default>
  )
}

export default Home

const HomeWrap = styled.div`
  ${GridBase};
  padding-top: 60px;
`

const HomeContent = styled.div`
  z-index: 501;
  position: relative;
  height: 100%;
  min-height: calc(100vh - 60px);
  @media (max-width: 768px) {
    min-height: calc(100vh - 60px);
  }
  /* padding-bottom: 4em; */
  padding: 0 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${colorScheme.text};
  ${ContentLimit};

  > div {
    max-width: 70ch;
    @media (min-width: 1024px) {
      max-width: 220ch;
    }
  }

  h1 {
    color: ${colorScheme.support};
    font-size: 2em;
    font-weight: 900;
    margin: 0;
    @media (min-width: 768px) {
      font-size: 3em;
    }
    @media (min-width: 1024px) {
      font-size: 5em;
    }
  }

  h2 {
    font-size: 1.75em;
    margin: 0;
    @media (min-width: 768px) {
      font-size: 2.25em;
    }
    @media (min-width: 1024px) {
      font-size: 4em;
    }
  }

  p {
    line-height: 1.55;
    font-size: 1.125em;
    @media (min-width: 768px) {
      font-size: 1.25em;
    }
    @media (min-width: 768px) {
      font-size: 1.45em;
      line-height: 1.25;
    }
  }

  a {
    text-decoration: none;
    color: ${colorScheme.supportPink};
    &:hover {
      text-decoration: underline;
    }
  }
`

const HomeBackground = styled.img`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: right;
  z-index: 1;
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`

// const HomeImg = css`
//   width: 100%;
//   height: 100%;
//   position: fixed !important;
//   top: 0;
//   left: 0;
//   object-fit: cover;
//   z-index: 1;

//   img:nth-child(2) {
//     opacity: 1 !important;
//   }

//   img:last-child {
//     opacity: 0 !important;
//   }
// `
export const query = graphql`
  query Index_2_Query($category: String) {
    allFile(filter: { name: { eq: "bg" } }) {
      edges {
        node {
          id
          name
          sourceInstanceName
          childImageSharp {
            id
            fluid(traceSVG: { blackOnWhite: false, color: "#37474F" }) {
              base64
              tracedSVG
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              originalImg
              originalName
            }
          }
        }
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { frontmatter: { date: DESC } }
      filter: { frontmatter: { category: { eq: $category } } }
    ) {
      totalCount
      edges {
        node {
          ...defaultArchiveQuery
        }
      }
    }
  }
`
