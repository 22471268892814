import * as React from "react"
import map from "lodash/map"
import PostExcerpt from "../PostExcerpt/PostExcerpt"
import { MarkdownRemark } from "../../types"
interface PostListProps {
  edges: [MarkdownRemark]
  context?: string
}

const PostsList = (props: PostListProps) => {
  const getPostList = () =>
    map(props.edges, (postEdge: MarkdownRemark) => ({
      published: postEdge.node.frontmatter.published,
      path: postEdge.node.fields.slug,
      tags: postEdge.node.frontmatter.tags,
      category: postEdge.node.frontmatter.category,
      cover: postEdge.node.frontmatter.cover,
      title: postEdge.node.frontmatter.title,
      date: postEdge.node.frontmatter.date,
      excerpt: postEdge.node.excerpt,
      timeToRead: postEdge.node.timeToRead,
    }))

  /**
   * When in production mode, don't render unpublished posts in the postlist
   */
  const renderPostExcerpt = () => {
    const postList = getPostList()

    return map(postList, (post) => {
      if (process.env.NODE_ENV === "production" && post.published) {
        return (
          <PostExcerpt
            context={props.context}
            key={post.title}
            postInfo={post}
          />
        )
      }
      // Show all posts or games publish or not published
      if (process.env.NODE_ENV === "development") {
        return (
          <PostExcerpt
            context={props.context}
            key={post.title}
            postInfo={post}
          />
        )
      }
      return false
    })
  }

  return <>{renderPostExcerpt()}</>
}

export default PostsList
